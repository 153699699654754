<template>
  <AppLayout>
    <template v-slot:appContent>
      <section class="users-list-wrapper">
        <div class=" d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h2 class="">Invoice</h2>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="card mb-5">
                <div class="card-body ">
                  <div class="row" id="printDiv">

                    <div class="col-md-6 form-group" v-if="franchiseePayment.franchisee !== undefined">
                      <div class="rounded border p-2 h-1000">
                        <h5 class="font-weight-normal text-uppercase mb-0">
                          {{
                            `${franchiseePayment.franchisee.user.first_name} ${franchiseePayment.franchisee.user.last_name}`
                          }}</h5>
                        <p class="mb-0"><strong>ABN: {{ franchiseePayment.franchisee.abn }}</strong></p>
                        <p class="mb-0">{{ address }}</p>
                      </div>

                    </div>

                    <div class="col-md-6 form-group">
                      <div class="p-2 rounded border h-100">
                        <table class="invoiceDetails">
                          <tbody>
                          <tr>
                            <th>Invoice No</th>
                            <td>{{ }}</td>
                          </tr>
                          <tr>
                            <th>Date</th>
                            <td>01 February, 2022</td>
                          </tr>
                          <tr>
                            <th>Time period</th>
                            <td>{{ `${franchiseePayment.from_date} - ${franchiseePayment.to_date}` }}</td>
                          </tr>
                          <!--                          <tr>
                                                      <th>Hourly rate</th>
                                                      <td>${{ parseFloat(technicianPayment.hours_rate / 100).toFixed(2) }}</td>
                                                    </tr>-->
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div class="col-12 form-group">
                      <h4 class="invoiceHeader text-center">
                        <span class="bg-white px-1">Invoice Issued by Franchisee</span>
                      </h4>
                    </div>

                    <div class="col-md-6 form-group ">
                      <div class="p-2 rounded border h-100">
                        <span class="sectionHeader">Invoice To</span>
                        <p class="mb-0">{{ companyName }}</p>
                        <p class="mb-0">ABN: {{ companyABN }}</p>
                      </div>
                    </div>


                    <div class="col-md-6 form-group" v-if="franchiseePayment.franchisee !== undefined">
                      <div class="p-2 rounded border h-100">
                        <span class="sectionHeader">Banking Details</span>
                        <table class="invoiceDetails">
                          <tbody>
                          <tr>
                            <th>Bank Name</th>
                            <td>
                              {{ franchiseePayment.franchisee?.user?.bankAccount?.bank_name ?? '' }}
                            </td>
                          </tr>
                          <tr>
                            <th>Account Name</th>
                            <td>
                              {{ franchiseePayment.franchisee?.user?.bankAccount?.account_name ?? '' }}
                            </td>
                          </tr>
                          <tr>
                            <th>Account</th>
                            <td>
                              {{ franchiseePayment.franchisee?.user?.bankAccount?.account_number ?? '' }}
                            </td>
                          </tr>
                          <tr>
                            <th>BSB</th>
                            <td>
                              {{ franchiseePayment.franchisee?.user?.bankAccount?.bsb ?? '' }}
                            </td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <!--                    appointment table start -->
                    <div class="col-12" v-if="franchiseePayment.payment_for === 'Appointment'">
                      <div class="table-responsive">
                        <table class="table">
                          <thead class="thead-light">
                          <tr>
                            <th scope="col">Task List</th>
                            <th scope="col">Commission Rate</th>
                            <th scope="col">Service Price</th>
                            <th scope="col" class="text-right">Franchisee <br> Commission</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(details, index) in franchiseePayment.details" :key="index">
                            <td>
                              <p class="mb-0">Reference{{ `# ${details?.appointment?.reference}` }} -
                                {{ details?.appointment?.service.name }}</p>
                              <!--                              <p class="small text-secondary mb-0">Was booked - {{ details.bookingDate }}, Was on -
                                                              {{ details.closingDate }} </p>-->
                            </td>
                            <td>{{
                                parseFloat(franchiseePayment.royalty_percentage + franchiseePayment.operation_percentage + franchiseePayment.marketing_percentage)
                              }} %
                            </td>
                            <td>${{ parseFloat(details.appointmentTotalPrice / 100).toFixed(2) }}</td>
                            <td class="text-right">
                              ${{ parseFloat(details.franchiseeCommissionFromAppointment / 100).toFixed(2) }}
                            </td>
                          </tr>

                          </tbody>
                          <tfoot>
                          <!--                          <tr>
                                                      <td colspan="3" class="border-0 text-right pb-0">Total Hour(s)</td>
                                                      <td class="border-top-0 text-right pb-0 border-0">{{ summarizedData.hours }}</td>
                                                    </tr>
                                                    <tr>
                                                      <td colspan="3" class="border-0 text-right pb-0">Total Service</td>
                                                      <td class="border-top-0 text-right pb-0 border-0">
                                                        ${{ parseFloat(summarizedData.total / 100).toFixed(2) }}
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td colspan="3" class="border-0 text-right pb-0"> Product Commission(order) (+)
                                                      </td>
                                                      <td class="border-top-0 text-right pb-0 border-0">
                                                        ${{ parseFloat(summarizedData.productCommission / 100).toFixed(2) }}
                                                      </td>
                                                    </tr>-->
                          <tr>
                            <td colspan="3" class="border-0 text-right pb-0">Total Commission</td>
                            <td class="border-top-0 text-right pb-0 border-0">
                              ${{
                                parseFloat((franchiseePayment.total) / 100).toFixed(2)
                              }}
                            </td>
                          </tr>
                          </tfoot>
                        </table>

                      </div>
                    </div>
                    <!--                    appointment table end -->

                    <!--                    order table start -->
                    <div class="col-12" v-if="franchiseePayment.payment_for === 'Order'">
                      <div class="table-responsive p-2 border"  v-for="(franchiseeOrder,index) in franchiseePayment.details"
                           :key="index">
                        <table class="table"
                              >
                          <thead class="thead-light">
                          <tr v-if="index === 0">
                            <th class="position-relative" :style="{width:'10%'}">REFERENCE ID</th>
                            <th class="position-relative" :style="{width:'15%'}">PRODUCT SL</th>
                            <th class="position-relative" :style="{width:'15%'}">SELL PRICE</th>
                            <th class="position-relative" :style="{width:'15%'}">PURCHASE COST</th>
                            <th class="position-relative" :style="{width:'15%'}">PROFIT</th>
                            <th class="position-relative" :style="{width:'15%'}">COMMISSION</th>
                            <th class="position-relative" :style="{width:'15%'}">Product with commission</th>
                          </tr>
                          </thead>
                          <tbody>
                          <tr v-for="(singleItem,itemIndex) in franchiseeOrder.orderProductList" :key="itemIndex">
                            <td :style="{width:'10%',borderBottom:'none'}" v-if="itemIndex === 0"
                                :rowspan="franchiseeOrder.orderProductList.length"
                                class="text-bold-500">
                              <!--                      <div class="custom-control custom-checkbox mb-2 mt-1">
                                                      <input type="checkbox" class="custom-control-input" id="accept" value="1">
                                                      <label for="accept" class="custom-control-label">Accept</label>
                                                    </div>-->
                              {{ franchiseeOrder.order.reference }}
                            </td>
                            <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'franchisee-payment-table-bg'">
                              {{ singleItem.productItem.serialNumber }}
                            </td>
                            <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'franchisee-payment-table-bg'">
                              {{ parseFloat(singleItem.sellPrice / 100).toFixed(2) }}
                            </td>
                            <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'franchisee-payment-table-bg'">
                              {{ parseFloat(singleItem.purchaseCost / 100).toFixed(2) }}
                            </td>
                            <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'franchisee-payment-table-bg'">
                              {{ parseFloat(singleItem.profit / 100).toFixed(2) }}
                            </td>
                            <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'franchisee-payment-table-bg'">
                              {{ parseFloat(singleItem.commission / 100).toFixed(2) }}
                            </td>
                            <td :style="{width:'15%'}" :class="singleItem.isFranchiseeProduct === true && 'franchisee-payment-table-bg'">
                              {{ parseFloat(singleItem.productValue / 100).toFixed(2) }}
                            </td>
                          </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>
                    <!--                    order table start -->

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <hr>
                  <div class="row">
                    <!-- <div class="col-md-6">
                      <button type="button" class="btn btn-link font-weight-bold text-uppercase p-0 border-0">
                        <a href=""
                           class="font-weight-bold text-uppercase pr-5">Back</a>
                      </button>
                      <button type="button" class="btn btn-link font-weight-bold text-uppercase p-0 border-0"
                              data-toggle="modal" data-target="#mailCustomer">
                        Mail to Technician
                      </button>
                    </div> -->
                    <div class="col-md-6 text-md-right">
                      <button type="button" @click="printAction"
                              class="btn btn-link font-weight-bold text-uppercase p-0 border-0">Print
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
  </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'

import {mapActions, mapGetters} from "vuex";
import printElement from "@/components/backEnd/mixins/PrintElement";
import Loader from "@/components/backEnd/mixins/Loader";

export default {
  name: "ViewFranchiseePayment",
  components: {
    AppLayout,
  },
  mixins: [Loader, printElement],
  data() {
    return {
      postAdditionTechnicianExtraCommission: {
        tech_com_pay_id: '',
        technician_id: '',
        note: '',
        amount: '',
        commission_type: 1
      },
      postDeductionTechnicianExtraCommission: {
        tech_com_pay_id: '',
        technician_id: '',
        note: '',
        amount: '',
        commission_type: 2
      },
      technicalValue: {
        name: 'Any ',
        language: 'Any '
      },

      customerTypeSelectedValue: '',
      statusSelectedValue: '',

      currentGeekDetails: {
        id: "",
        name: "",
        address: "",
        type: "",
        phone: "",
        email: "",
        status: "",

      },
      getFranchiseePaymentsParams: {
        with_relation: [
          'franchisee.user',
          'franchisee.user.bankAccount',
          'franchisee.address.business'
        ]
      },
      technician: {},
      address: '',
      detailsData: [],
      summarizedData: {},
      getSettingParams: {
        type: ['default'],
        key: [
          'default_pagination',
          'default_company_name',
          'default_company_abn',

        ],
      },
    }

  },
  computed: {
    ...mapGetters({
      franchiseePayment: 'appFranchiseePayments/franchiseePayment',
    }),
    companyName() {
      return this.$store.getters["appSettings/settingDefaultCompanyName"].value;
    },
    companyABN() {
      return this.$store.getters["appSettings/settingDefaultCompanyABN"].value;
    },
    isAdditionDataFiled() {
      return !(this.postAdditionTechnicianExtraCommission.amount && this.postAdditionTechnicianExtraCommission.note);
    },
    isDeductionDataFiled() {
      return !(this.postDeductionTechnicianExtraCommission.amount !== '' && this.postDeductionTechnicianExtraCommission.note !== '');
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getFranchiseePayment: 'appFranchiseePayments/getFranchiseePayment',

    }),

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },
    async getSingleFranchiseePayment(id) {
      const paramObj = {
        id: id,
        params: this.getFranchiseePaymentsParams,
      };

      await this.getFranchiseePayment(paramObj).then((response) => {
        if (response.status === 200) {
          let total = 0;
          let details = JSON.parse(this.franchiseePayment.details);
          if (this.franchiseePayment.payment_for === 'Appointment') {
            total = details.reduce((accu, cur) => accu + cur.franchiseeCommissionFromAppointment, 0);
          } else {
            total = details.map((item) =>
                item.orderProductList.reduce((acc, cur) => acc + cur.productValue, 0)
            ).reduce((acc, cur) => acc + cur, 0);
          }
          this.franchiseePayment['total'] = total;
          this.franchiseePayment.details = details;
          this.address = this.fullAddress(this.franchiseePayment.franchisee);
        }
        if (response && response.message && response.status !== 200) {
          this.showToastMessage(response);
        }
      });
    },

    fullAddress(obj) {
      let addressStreet = obj.address.street;
      let addressSuburb = obj.address.suburb;
      let addressState = obj.address.state.toUpperCase();
      let addressPostCode = obj.address.post_code;
      let addressCountry = obj.address.country;
      return `${addressStreet}, ${addressSuburb} ${addressState} ${addressPostCode}, ${addressCountry}`;
    },

    printAction() {
      let idElement = document.getElementById('printDiv');
      this.printElement(idElement);
    }
  },
  async mounted() {
    this.loader(true);
    const routeParams = this.$route.params ?? null;
    await this.getSettingList(this.getSettingParams);
    await this.getSingleFranchiseePayment(routeParams.id);
    this.loader(false);
  }

}
</script>

<style scoped>
.franchisee-payment-table-bg{
  background-color: #f9f9f9;
}
.table th, .table td {
  padding: 4px 10px;

}
p {
  margin-bottom: 0;
}

.invoiceHeader:after {
  content: '';
  display: block;
  width: 100%;
  height: 4px;
  margin-top: -14px;
  border-top: 1px solid #DFE3E7;
  border-bottom: 1px solid #DFE3E7;
}


</style>
